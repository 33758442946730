import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"dense":"","disable-filtering":"","disable-sort":"","footer-props":{
          'items-per-page-options': [10, 20],
          'items-per-page-text': 'Itens por página',
          'page-text': '{0}-{1} de {2}'
        },"headers":_vm.headers,"items":_vm.computedItems},scopedSlots:_vm._u([{key:"item.origem",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.origem))])]}},{key:"item.destino",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.destino))])]}},{key:"item.distancia",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.distancia, 0, 0)))])]}},{key:"item.quantidade",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.quantidade, 0, 0)))])]}},{key:"item.valorPorM2",fn:function(ref){
        var item = ref.item;
return [_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v("R$")]),_c(VCol,{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valorPorM2, 2, 2)))])],1)]}},{key:"item.valorTotalM2",fn:function(ref){
        var item = ref.item;
return [_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v("R$")]),_c(VCol,{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valorTotalM2, 0, 0)))])],1)]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"white grey--text text--darken-2"},[_c('th',[_vm._v("Total")]),_c('th'),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(_vm.distanciaTotal, 0, 0)))])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(_vm.quantidadeTotal, 0, 0)))])]),_c('th',{staticClass:"text-right"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v("R$")]),_c(VCol,[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(_vm.quantidadeTotal>0?_vm.valorTotalFreteM2/_vm.quantidadeTotal:0, 2, 2)))])],1)],1),_c('th',{staticClass:"text-right"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v("R$")]),_c(VCol,[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(_vm.valorTotalFreteM2, 0, 0)))])],1)],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }