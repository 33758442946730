<template>
  <v-data-table
      dense
      disable-filtering
      disable-sort
      :footer-props="{
          'items-per-page-options': [10, 20],
          'items-per-page-text': 'Itens por página',
          'page-text': '{0}-{1} de {2}'
        }"
      :headers="headers"
      :items="computedItems"
  >
    <template v-slot:[`item.origem`]="{ item }">
      <span>{{ item.origem }}</span>
    </template>
    <template v-slot:[`item.destino`]="{ item }">
      <span>{{ item.destino }}</span>
    </template>
    <template v-slot:[`item.distancia`]="{ item }">
      <span>{{ $stringFormat.formatNumber(item.distancia, 0, 0) }}</span>
    </template>
    <template v-slot:[`item.quantidade`]="{ item }">
      <span>{{ $stringFormat.formatNumber(item.quantidade, 0, 0) }}</span>
    </template>
    <template v-slot:[`item.valorPorM2`]="{ item }">
      <v-row dense>
        <v-col cols="auto">R$</v-col>
        <v-col class="text-right">{{ $stringFormat.formatNumber(item.valorPorM2, 2, 2) }}</v-col>
      </v-row>
    </template>
    <template v-slot:[`item.valorTotalM2`]="{ item }">
      <v-row dense>
        <v-col cols="auto">R$</v-col>
        <v-col class="text-right">{{ $stringFormat.formatNumber(item.valorTotalM2, 0, 0) }}</v-col>
      </v-row>
    </template>
    <template slot="body.append">
      <tr class="white grey--text text--darken-2">
        <th>Total</th>
        <th></th>
        <th class="text-center">
          <span>{{ $stringFormat.formatNumber(distanciaTotal, 0, 0) }}</span>
        </th>
        <th class="text-center">
          <span>{{ $stringFormat.formatNumber(quantidadeTotal, 0, 0) }}</span>
        </th>
        <th class="text-right">
          <v-row dense>
            <v-col cols="auto">R$</v-col>
            <v-col>{{ $stringFormat.formatNumber(quantidadeTotal>0?valorTotalFreteM2/quantidadeTotal:0, 2, 2) }}</v-col>
          </v-row>
        </th>
        <th class="text-right">
          <v-row dense>
            <v-col cols="auto">R$</v-col>
            <v-col>{{ $stringFormat.formatNumber(valorTotalFreteM2, 0, 0) }}</v-col>
          </v-row>
        </th>
      </tr>
    </template>
</v-data-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    headers: [{
      class: 'sticky-header custom-table--header',
      text: 'Origem',
      value: 'origem',
    }, {
      class: 'sticky-header custom-table--header',
      text: 'Destino',
      value: 'destino',
    }, {
      class: 'sticky-header custom-table--header',
      align: 'center',
      text: 'Distancia (km)',
      value: 'distancia',
    }, {
      class: 'sticky-header custom-table--header',
      align: 'center',
      text: 'Quantidade (m²)',
      value: 'quantidade',
    }, {
      class: 'sticky-header custom-table--header',
      align: 'center',
      text: 'Vl Unit (m²)',
      value: 'valorPorM2',
    }, {
      class: 'sticky-header custom-table--header',
      align: 'center',
      text: 'Vl Total (m²)',
      value: 'valorTotalM2',
    }],
  }),

  computed: {
    computedItems: function () {
      const items = []

      this.items.forEach(function (item) {
        items.push(item.simulacao)
      })

      return items
    },

    distanciaTotal: function () {
      let sum = 0

      this.items.forEach((item) => {
        sum += item.simulacao.distancia
      })

      return sum
    },

    quantidadeTotal: function () {
      let sum = 0

      this.items.forEach((item) => {
        sum += item.simulacao.quantidade
      })

      return sum
    },

    valorTotalFreteM2: function () {
      let sum = 0

      this.items.forEach((item) => {
        sum += item.simulacao.valorTotalM2
      })

      return sum
    },
  },
}
</script>
