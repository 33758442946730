import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardText,[_c(VDataTable,{attrs:{"dense":"","disable-filtering":"","disable-sort":"","footer-props":{
        'items-per-page-options': [5, 10, 20, 30, 40, 50],
        'items-per-page-text': 'Registros por página',
        'page-text': '{0}-{1} de {2}'
      },"headers":_vm.headers,"items":_vm.itens,"items-per-page":20,"loading":_vm.loadingItens,"options":_vm.options,"server-items-length":_vm.itensTotal,"show-expand":"","single-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.origem",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.simulador_frete_rotas[0].simulacao.origem))])]}},{key:"item.destino",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.simulador_frete_rotas[item.simulador_frete_rotas.length-1].simulacao.destino))])]}},{key:"item.paradas",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.simulador_frete_rotas.length))])]}},{key:"item.distancia",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(_vm.sumQuantidadeTotalKm(item.simulador_frete_rotas), 0, 0)))])]}},{key:"item.quantidade",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(_vm.sumQuantidadeTotalM2(item.simulador_frete_rotas), 0, 0)))])]}},{key:"item.valorPorM2",fn:function(ref){
      var item = ref.item;
return [_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v("R$")]),_c(VCol,{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$stringFormat.formatNumber( _vm.sumQuantidadeTotalM2(item.simulador_frete_rotas)>0?_vm.sumValorTotalM2(item.simulador_frete_rotas)/_vm.sumQuantidadeTotalM2(item.simulador_frete_rotas):0, 2, 2 ))+" ")])],1)]}},{key:"item.valorTotalM2",fn:function(ref){
      var item = ref.item;
return [_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v("R$")]),_c(VCol,{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$stringFormat.formatNumber(_vm.sumValorTotalM2(item.simulador_frete_rotas), 0, 0))+" ")])],1)]}},{key:"item.valorPorKm",fn:function(ref){
      var item = ref.item;
return [_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v("R$")]),_c(VCol,{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$stringFormat.formatNumber( _vm.sumQuantidadeTotalKm(item.simulador_frete_rotas)>0?_vm.sumValorTotalKm(item.simulador_frete_rotas)/_vm.sumQuantidadeTotalKm(item.simulador_frete_rotas):0, 2, 2 ))+" ")])],1)]}},{key:"item.valorTotalKm",fn:function(ref){
      var item = ref.item;
return [_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v("R$")]),_c(VCol,{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$stringFormat.formatNumber(_vm.sumValorTotalKm(item.simulador_frete_rotas), 0, 0))+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,'DD/MM/YYYY')))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push(("/fretes/simulador-fretes/" + (item.id)))}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Clonar")])])]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VCard,{staticClass:"transparent my-4",attrs:{"flat":"","tile":""}},[_c(VCardTitle,[_vm._v(" Simulação ")]),_c('detalhamento-rotas-table',{attrs:{"items":item.simulador_frete_rotas}})],1)],1)]}}],null,true)})],1)],1),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }