<template>
  <div>
    <FiltroMobile :has-change="change" @search="seachFilter">
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-text-field
            v-model="codigo"
            clearable
            dense
            label="Código"
            outlined
            persistent-placeholder
            @click:clear="change = true"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <empresas-select
            v-model="sviId"
            clearable
            persistent-placeholder
            svi
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
      <producoes-select
          v-model="producao"
          clearable
          :empresa="sviId"
          label="Origem"
          persistent-placeholder
          return-object
          svi
          @change="change = true"
      />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <usuarios-agricola-select
            v-model="usuarioId"
            clearable
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker
            v-model="dataInicial"
            clearable
            label="Data Inicial"
            persistent-placeholder
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker
            v-model="dataFinal"
            clearable
            label="Data Final"
            persistent-placeholder
            @change="change = true"
        />
      </v-col>
      <template v-slot:outros>
      </template>
    </FiltroMobile>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => ({
    change: false,
    codigo: null,
    sviId: null,
    producao: null,
    empresaId: null,
    producaoId: null,
    usuarioId: null,
    dataInicial: null,
    dataFinal: null,
  }),
  mounted () {
    this.codigo = this.value?.codigo
    this.sviId = this.value?.sviId
    this.producao = this.value?.empresaId + String(this.value?.producaoId).padStart(5, '0')
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.usuarioId = this.value?.usuarioId
    this.dataInicial = this.value?.dataInicial
    this.dataFinal = this.value?.dataFinal
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    seachFilter () {
      this.change = false

      this.model = {
        codigo: this.codigo,
        sviId: this.sviId,
        empresaId: this.producao?.empresa_id,
        producaoId: this.producao?.producao_id,
        usuarioId: this.usuarioId,
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal,
      }
    },
  },
}
</script>
