<template>
  <div>
    <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
      <v-btn
          color="primary"
          dark
          class="mr-3"
          title="Remover Filtros"
          @click="removeFiltros"
      >
        <v-icon>mdi-filter-remove</v-icon>
      </v-btn>
      <HeaderControl v-model="model" :headers="headersOptions" local-storage-save-name="simulador-fretes"></HeaderControl>
      <v-spacer />
      <v-btn
          color="primary"
          dark
          @click="$router.push('/fretes/simulador-fretes/novo')"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
    filtro: {
      type: Object,
    },
  },
  data: () => {
    return {
      headers: [],
      headersOptions: [{
        value: 'data-table-expand',
        align: 'right p-0',
        class: 'sticky-header custom-table--header',
      }, {
        align: 'left',
        text: 'Código',
        value: 'id',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'left',
        text: 'Origem',
        value: 'origem',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'left',
        text: 'Destino',
        value: 'destino',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'center',
        text: 'Paradas',
        value: 'paradas',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'center',
        text: 'Distancia (km)',
        value: 'distancia',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'center',
        text: 'Quantidade (m2)',
        value: 'quantidade',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'center',
        text: 'Vl Unit (m2)',
        value: 'valorPorM2',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'center',
        text: 'Vl Total (m2)',
        value: 'valorTotalM2',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'center',
        text: 'Vl Unit (km)',
        value: 'valorPorKm',
        class: 'sticky-header custom-table--header',
        ativado: false,
      }, {
        align: 'center',
        text: 'Vl Total (km)',
        value: 'valorTotalKm',
        class: 'sticky-header custom-table--header',
        ativado: false,
      }, {
        align: 'left',
        text: 'Usuário',
        value: 'usuario.name',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'center',
        text: 'Data',
        value: 'created_at',
        width: '0',
        class: 'sticky-header custom-table--header',
        ativado: true,
      }, {
        align: 'center',
        text: '',
        value: 'actions',
        width: '0',
        class: 'sticky-header custom-table--header',
        obrigatorio: true,
      }],
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    removeFiltros () {
      this.$router.push({ query: null })
      window.location.reload()
    },
  },
}
</script>

<style scoped>

</style>
