<template>
  <div>
    <actions-listagem-simulacoes-frete-component v-model="headers" :filtro="filtro" />
    <filter-listagem-simulacoes-frete-component v-model="filtro"/>
    <visualiza-simulacoes-frete-components :filtros="filtro" :headers="headers"/>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import FilterListagemSimulacoesFreteComponent from '@/view/pages/fretes/simulador-fretes/components/FilterListagemSimulacoesFreteComponent'
import ActionsListagemSimulacoesFreteComponent from '@/view/pages/fretes/simulador-fretes/components/ActionsListagemSimulacoesFreteComponent'
import VisualizaSimulacoesFreteComponents from '@/view/pages/fretes/simulador-fretes/components/VisualizaSimulacoesFreteComponents'

export default {
  components: {
    VisualizaSimulacoesFreteComponents,
    ActionsListagemSimulacoesFreteComponent,
    FilterListagemSimulacoesFreteComponent,
  },

  data: (instance) => ({
    filtro: {
      codigo: instance.$route.query.codigo ? parseInt(instance.$route.query.codigo) : null,
      sviId: instance.$route.query.sviId ? parseInt(instance.$route.query.sviId) : null,
      empresaId: instance.$route.query.producaoId ? instance.$route.query.empresaId : null,
      producaoId: instance.$route.query.producaoId ? instance.$route.query.producaoId : null,
      usuarioId: instance.$route.query.usuarioId ? instance.$route.query.usuarioId : null,
      dataInicial: instance.$route.query.dataInicial ? instance.$route.query.dataInicial : null,
      dataFinal: instance.$route.query.dataFinal ? instance.$route.query.dataFinal : null,
    },
    headers: [],
  }),

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Fretes', route: 'simulador-fretes' },
      { title: 'Simulador de Fretes' },
    ])
  },
}
</script>
